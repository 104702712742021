<script setup lang="ts">
import { useAuth } from '~/composables/useAuth';

import type { FormError, FormSubmitEvent } from '#ui/types';

const { login } = useAuth();

const state = reactive({
	email: undefined,
	password: undefined,
	errorMessage: '',
});

const validate = (state: any): FormError[] => {
	const errors = [];
	if (!state.email) errors.push({ path: 'email', message: 'Required' });
	if (!state.password) errors.push({ path: 'password', message: 'Required' });
	return errors;
};

async function authFailed(state: any) {
	state.errorMessage = 'Authentication failed. Please check your email and password.';
}

async function onSubmit(event: FormSubmitEvent<any>) {
	try {
		await login(event.data.email, event.data.password);
		const isAdmin = useState('isAdmin');
		if (isAdmin.value) {
			navigateTo('/admin');
			return;
		}
		navigateTo('/');
	}
	catch (error) {
		authFailed(state);
	}
}
</script>

<template>
	<div class="w-screen h-screen bg-metgisblue-50 items-center z-10 absolute">
		<div class="relative mt-20 text-yellow-500 lg:text-5xl text-3xl font-bold mx-auto text-center">
			MetGis Weather Warnings
		</div>
		<div class="bg-gray-600 relative lg:top-1/4 top-20 lg:w-2/4 w-full z-20 shadow-black shadow-lg mx-auto p-4 rounded-md">
			<UForm
				:validate="validate"
				:state="state"
				class="space-y-4 z-20"
				@submit="onSubmit"
			>
				<UFormGroup
					label="Email"
					name="email"
				>
					<UInput v-model="state.email" />
				</UFormGroup>

				<UFormGroup
					label="Password"
					name="password"
				>
					<UInput
						v-model="state.password"
						type="password"
					/>
				</UFormGroup>

				<div
					v-if="state.errorMessage"
					class="text-red-500 text-lg"
				>
					{{ state.errorMessage }}
				</div>

				<UButton type="submit">
					Submit
				</UButton>
			</UForm>
		</div>
	</div>
</template>

<style scoped>
input, .UInput, .UButton {
  pointer-events: auto;
}
</style>
